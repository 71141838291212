import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'antd';
import { i18n } from 'language/i18n';

import FormItem from './FormItem';

const CustomCheckbox = ({
  name = '', text = '', value, onChange = () => {}, required = false, ...props
}) => {
  const onChangeText = (event) => {
    const { target } = event;
    const inputValue = target.checked;
    onChange({
      name,
      value: inputValue,
    });
  };
  return (
    <FormItem
      name={name}
      valuePropName="checked"
      rules={[{
        validator: (rule, val) => {
          if (required) {
            return val && required ? Promise.resolve() : Promise.reject(i18n.t('cantBeEmpty'));
          }
          return Promise.resolve();
        },
      }]}
    >
      <Checkbox
        onChange={onChangeText}
        checked={value}
        {...props}
      >
        {text}
      </Checkbox>
    </FormItem>
  );
};

CustomCheckbox.propTypes = {
  name: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onChange: PropTypes.func,
  value: PropTypes.bool,
  required: PropTypes.bool,
};

export default CustomCheckbox;
