import React from 'react';
import PropTypes from 'prop-types';

import FooterShort from './FooterShort';
import FooterTall from './FooterTall';

const Footer = ({ shortFooter }) => (
  <>
    {
      shortFooter
        ? <FooterShort />
        : <FooterTall />
    }
  </>
);

Footer.propTypes = {
  shortFooter: PropTypes.bool,
};

export default Footer;
