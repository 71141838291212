/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'language/i18n';
import { Button } from 'components/common';
import { StatusContext } from 'contexts/statusContext';
import { UserContext } from 'contexts/userContext';
import { updateBuyingLimit } from 'models/user';

const Request = ({ t, setVisible, setSuccessSectionVisible }) => {
  const { setErrorState } = useContext(StatusContext);
  const { userData } = useContext(UserContext);

  const [onResponse, setOnResponse] = useState(false);

  const onPressAccept = async () => {
    if (!onResponse) {
      try {
        setOnResponse(true);
        await updateBuyingLimit(userData?.id);
        setSuccessSectionVisible(true);
      } catch (error) {
        console.log('onPressLimitIncreaseErr', error);
        if (error.status) {
          setVisible(false);
          setTimeout(() => {
            setErrorState({
              title: t('alert'),
              text: error.message,
              buttonText: t('close'),
              onClickButton: () => setErrorState({}),
            });
          }, 500);
        }
      } finally {
        setOnResponse(false);
      }
    }
  };

  return (
    <div className="im-box im-box--white im-box--border-radius im-box--padding-30">
      <div className="row">
        <div className="col">
          <div className="row mb-5">
            <div className="col text-center">
              <div className="fs-22 fw-700">Limit Artırım Talebi</div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col text-center">
              <p className="fs-15">
                Limit artırım talebinizi onaylamanız halinde, açık artırma yatırdığınız ve hesabınızın güncel limiti incelenecektir.
              </p>
              <p className="fs-15">
                Uygun görülmesi halinde müşteri temsilcimiz en yakın zamanda sizinle iletişime geçecek ve süreci ilerletecektir.
              </p>
              <p className="fs-15">
                Limit artırım talebinizi onaylıyor musunuz?
              </p>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col text-center">
              <Button
                className="im-button im-button--outline-yellow2 im-button--size-60 im-button--center fw-900 col-15 mt-1"
                label="Hayır, Onaylamıyorum"
                onClick={() => setVisible(false)}
              />
            </div>
            <div className="col text-center">
              <Button
                className="im-button im-button--primary im-button--size-60 im-button--center fw-900 col-15 mt-1"
                label="Evet, Onaylıyorum"
                loading={onResponse}
                onClick={onPressAccept}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Request.propTypes = {
  t: PropTypes.func,
  setVisible: PropTypes.func,
  setSuccessSectionVisible: PropTypes.func,
};

export default withTranslation('common')(Request);
