import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';

import { i18n } from 'language/i18n';
import { UserContext } from 'contexts/userContext';
import HeaderDesktop from './headerDesktop/HeaderDesktop';
import HeaderMobile from './headerMobile/index';
import HeaderWhite from './HeaderWhite';

const Header = ({ blackHeader, shortHeader }) => {
  const { userData, isLoggedIn, logout } = useContext(UserContext);

  // eslint-disable-next-line no-unused-vars
  const [language, setLanguage] = useState(i18n.language);

  const changeLanguage = () => {
    /* if (language === 'en') {
      i18n.changeLanguage('tr');
      setLanguage('tr');
    } else {
      i18n.changeLanguage('en');
      setLanguage('en');
    } */
  };

  const headerComponentProps = {
    changeLanguage,
    language,
    userData,
    isLoggedIn,
    logout,
    blackHeader,
  };

  return (
    <>
      {
        shortHeader
          ? <HeaderWhite />
          : (
            <>
              <HeaderMobile {...headerComponentProps} />
              <HeaderDesktop {...headerComponentProps} />
            </>
          )
      }
    </>
  );
};

Header.propTypes = {
  blackHeader: PropTypes.bool,
  shortHeader: PropTypes.bool,
};

export default Header;
