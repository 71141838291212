import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';

import { i18n } from 'language/i18n';
import FormItem from './FormItem';

const { TextArea } = Input;

const CustomTextArea = ({
  name = '', placeholder = '', value = '', onChange = () => {}, inputRef, validator, required = false, ...props
}) => {
  const onChangeText = (event) => {
    const { target } = event;
    const inputValue = target.value;

    onChange({
      name,
      value: inputValue,
    });
  };
  return (
    <FormItem
      label={placeholder}
      name={name}
      rules={[{ validator }, { required, message: i18n.t('cantBeEmpty') }]}
    >
      <TextArea
        placeholder={placeholder}
        autoComplete="off"
        onChange={onChangeText}
        value={value}
        ref={(input) => {
          if (inputRef) {
            inputRef(input);
          }
        }}
        autoSize={{ minRows: 4, maxRows: 10 }}
        {...props}
      />
    </FormItem>

  );
};

CustomTextArea.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  inputRef: PropTypes.func,
  validator: PropTypes.func,
  required: PropTypes.bool,
};

export default CustomTextArea;
