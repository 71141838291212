import React from 'react';
import Link from 'next/link';
import Image from 'next/image';

const HeaderWhite = () => (
  <header className="im-header im-header--bg-white im-header--padding-8">
    <div className="header-mobile d-lg-none">
      <Link href="/">
        <a className="logo logo--mobile d-block">
          <Image
            src="/image/logo-siyah.svg"
            width="151"
            height="45"
          />
        </a>
      </Link>
    </div>

    <div className="header-row d-none d-lg-flex">
      <div className="container">
        <div className="row">
          <div className="col">
            <Link href="/">
              <a className="logo d-block h-45">
                <Image
                  src="/image/logo-siyah.svg"
                  width="151"
                  height="45"
                />
              </a>
            </Link>

          </div>
        </div>
      </div>
    </div>
  </header>
);

export default HeaderWhite;
