/* eslint-disable consistent-return */
import request from 'utils/request';

export const getSource = async (type) => {
  const data = await request(`api/v1/source/${type}`, 'get');
  return data;
};

export const contactUsRequest = async ({
  fullName,
  company,
  email,
  phone,
  purpose,
  text,
}) => {
  const data = await request('api/v1/form/contact', 'post', {
    body: JSON.stringify({
      fullname: fullName,
      company,
      email,
      phone,
      purpose,
      text,
    }),
  });
  return data;
};

export const newsLetter = async ({
  fullName, company, email, phone, type,
}) => {
  const data = await request('api/v1/form/newsletter', 'post', {
    body: JSON.stringify({
      fullname: fullName,
      company,
      email,
      phone,
      type,
    }),
  });
  return data;
};

export const easySale = async ({
  fullName, company, email, phone, type,
}) => {
  const data = await request('api/v1/form/easySale', 'post', {
    body: JSON.stringify({
      fullname: fullName,
      company,
      email,
      phone,
      type,
    }),
  });
  return data;
};
export const userInfo = async ({
  fullName,
  kvkk,
  email,
  phone,
  isBuyer,
  isSeller,
}) => {
  const data = await request('api/v1/form/userInfo', 'post', {
    body: JSON.stringify({
      fullname: fullName,
      kvkk,
      email,
      phone,
      isBuyer,
      isSeller,
    }),
  });
  return data;
};
