import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';

const FormItem = ({
  name = '', label = '', children, ...props
}) => {
  const styles = {
    formItem: {
      marginBottom: 0,
    },
  };
  return (
    <Form.Item
      label={label}
      name={name}
      style={styles.formItem}
      {...props}
    >
      {children}
    </Form.Item>

  );
};

FormItem.propTypes = {
  name: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  children: PropTypes.element,
};

export default FormItem;
