/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import tr from 'react-phone-input-2/lang/tr.json';
import FormItem from './FormItem';

const DefaultPhoneInput = ({
  name = '', label = '', placeholder = '', value = '', onChange = () => {}, required = false, containerStyle, mobileNumber = false,
}) => {
  const [error, setError] = useState(false);

  const errorHandler = (val) => {
    if (required && val?.length < 12) {
      setError(true);
      return Promise.reject('Örnek Telefon Numarası: (5xx) xxx xx xx');
    }
    if (required && mobileNumber && val.startsWith('90') && !val.startsWith('905')) {
      setError(true);
      return Promise.reject('Örnek Telefon Numarası: (5xx) xxx xx xx');
    }
    error && setError(false);
    return Promise.resolve();
  };

  const onChangeText = (phone) => {
    onChange({
      name,
      value: phone,
    });
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', ...containerStyle }}>
      {
        (!_.isEmpty(label) || !_.isEmpty(placeholder))
        && (
          <div className="ant-row ant-form-item" style={{ marginBottom: 0 }}>
            <div className="ant-col ant-form-item-label">
              <label>
                <span style={{
                  marginRight: 4,
                  color: '#ff4d4f',
                  fontSize: 11,
                }}
                >
                  *
                </span>
                {!_.isEmpty(label) ? label : placeholder}
              </label>
            </div>
          </div>
        )
      }
      <FormItem
        name={name}
        valuePropName="input"
        rules={[{
          validator: (rule, val) => errorHandler(val),
        }]}
      >
        <PhoneInput
          inputClass="phone-input"
          country="tr"
          localization={tr}
          placeholder={placeholder}
          value={value || (mobileNumber && '5') || ''}
          masks={{ tr: '(...) ... .. ..' }}
          onChange={onChangeText}
          countryCodeEditable={false}
          inputStyle={{ width: '100%', borderColor: error && 'red' }}
        />
      </FormItem>
    </div>

  );
};

DefaultPhoneInput.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  required: PropTypes.bool,
  containerStyle: PropTypes.object,
  label: PropTypes.string,
  mobileNumber: PropTypes.bool,
};

export default DefaultPhoneInput;
