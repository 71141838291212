import React from 'react';
import PropTypes from 'prop-types';

import Head from './Head';
import Header from './header/Header';
import Footer from './footer/Footer';

const Layout = ({
  children, headTitle = 'iyiMakina', shortFooter, shortHeader, hideFooter, hideHeader, blackHeader,
}) => (
  <>
    <Head title={headTitle} />
    <div className="body">
      {!hideHeader && <Header shortHeader={shortHeader} blackHeader={blackHeader} />}
      {children}
      {!hideFooter && <Footer shortFooter={shortFooter} />}
    </div>
  </>
);

Layout.propTypes = {
  headTitle: PropTypes.string,
  shortFooter: PropTypes.bool,
  shortHeader: PropTypes.bool,
  hideFooter: PropTypes.bool,
  hideHeader: PropTypes.bool,
  blackHeader: PropTypes.bool,
  children: PropTypes.element,
};

export default Layout;
