import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'components/common';

const Success = ({ setVisible }) => {
  return (
    <div className="im-box im-box--white im-box--border-radius im-box--padding-30">
      <div className="row">
        <div className="col">
          <div className="row mb-5">
            <div className="col text-center">
              <div className="fs-22 fw-700">Limit Artırım Talebi</div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col text-center">
              <p className="fs-15">
                Limit Artırım talebiniz oluşturuldu.
              </p>
              <p className="fs-15">
                Uygun görülmesi halinde müşteri temsilcimiz en yakın zamanda sizinle iletişime geçecek ve süreci ilerletecektir.
              </p>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col text-center">
              <Button
                className="im-button im-button--primary im-button--size-60 im-button--center fw-900 col-15 mt-1"
                label="Kapat"
                onClick={() => setVisible(false)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Success.propTypes = {
  setVisible: PropTypes.func,
};

export default Success;
