/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { CaretDownOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import { i18n } from 'language/i18n';

import FormItem from './FormItem';

const { Option } = Select;

const CustomSelect = ({
  options = [], name = '', placeholder = '', value, onChange = () => {}, validator, required = false, ...props
}) => {
  const onChangeText = (val) => {
    onChange({
      name,
      value: val,
    });
  };
  return (
    <FormItem
      label={placeholder}
      name={name}
      rules={[{ validator }, { required, message: i18n.t('cantBeEmpty') }]}
    >
      <Select
        suffixIcon={<CaretDownOutlined />}
        onChange={onChangeText}
        allowClear
        value={value}
        placeholder={placeholder}
        {...props}
      >
        {
          _.map(options, (option, index) => (
            <Option key={index} value={option.value}>{option.text}</Option>
          ))
        }
      </Select>
    </FormItem>
  );
};

CustomSelect.propTypes = {
  options: PropTypes.array,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  validator: PropTypes.func,
  required: PropTypes.bool,
};

export default CustomSelect;
