import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { withTranslation } from 'language/i18n';
import { CallTo } from 'components/common';
import AuctionDropDown from '../AuctionDropDown';
import UserDropDown from './UserDropDown';

const HeaderDesktop = ({
  /* changeLanguage, language, */ t, userData, isLoggedIn, logout, blackHeader,
}) => {
  const [headerClass, setheaderClass] = useState('');

  const handleScroll = () => {
    if (window.scrollY > 20) {
      setheaderClass('im-header--fixed im-header--backgrounds');
    } else {
      setheaderClass('');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header className={`im-header ${blackHeader ? 'im-header--backgrounds' : ''} js-header-sticky ${headerClass}`}>
      <div className="header-row header-row-top d-none d-lg-flex">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="header-nav-container">
                <nav className="header-nav">
                  <Link href="/content/buying/before-bidding">
                    <a className="header-nav__link">{t('buying')}</a>
                  </Link>
                  <Link href="/content/selling/auction-process">
                    <a className="header-nav__link">{t('selling')}</a>
                  </Link>
                  <Link href="/about-us/">
                    <a className="header-nav__link">{t('aboutWith', { text: 'iyimakina.com' })}</a>
                  </Link>
                  <Link href="/faq/buyers">
                    <a className="header-nav__link">{t('faq')}</a>
                  </Link>
                </nav>
                <div className="header-nav-separator" />
                <nav className="header-nav">
                  <Link href="/contact-us">
                    <a className="header-nav__link header-nav__link--has-border">{t('contactUs')}</a>
                  </Link>

                  <div className="header-nav__link">
                    <i className="far fa-phone mr-1" />
                    <CallTo className="header-nav__link" tel="+90 (850) 885 46 25" />
                  </div>
                </nav>
              </div>
            </div>
            <div className="col-auto">
              <nav className="header-nav">
                {/* <div style={{ marginRight: 40, color: 'white' }}>
                  <a className={`header-nav__link ${language === 'en' ? 'fw-900' : 'fw-300'}`} style={{ marginRight: 5 }} onClick={() => changeLanguage()}>EN</a>
                  |
                  <a className={`header-nav__link ${language === 'tr' ? 'fw-900' : 'fw-300'}`} style={{ marginLeft: 5 }} onClick={() => changeLanguage()}>TR</a>
                </div> */}

                {
                  isLoggedIn
                    ? <UserDropDown user={userData} logout={logout} />
                    : (
                      <Link href="/login">
                        <a className="header-nav__link mr-1" href="sigup">{`${t('register')} / ${t('login')}`}</a>
                      </Link>
                    )
                }
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="header-row header-row-bottom header-row--bigger d-none d-lg-flex">
        <div className="container">
          <div className="row align-items-center">
            <div className="col">
              <Link href="/">
                <a className="logo d-block">
                  <img className="logo-white" src="/image/logo-desktop.svg" alt="" />
                  <img className="logo-black" src="/image/logo-siyah.svg" alt="" />
                </a>
              </Link>
            </div>
            <div className="col-auto d-flex align-items-center">
              <AuctionDropDown />
              <div className="separator" />
              {
                _.result(userData, 'isBuyer')
                  ? (
                    <Link href="/machine-sales">
                      <a
                        className="im-button im-button--size-small im-button--outline-white im-button--size-wide"
                      >
                        Makinamı Satmak İstiyorum
                      </a>
                    </Link>
                  )
                  : (
                    <Link href="/machinery">
                      <a
                        className="im-button im-button--size-small im-button--outline-white im-button--size-wide"
                      >
                        Açık Artırmaya Katıl
                      </a>
                    </Link>
                  )
              }
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

HeaderDesktop.propTypes = {
  t: PropTypes.func,
  userData: PropTypes.object,
  isLoggedIn: PropTypes.bool,
  logout: PropTypes.func,
  blackHeader: PropTypes.bool,
};

export default withTranslation('common')(HeaderDesktop);
