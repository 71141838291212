import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker, ConfigProvider } from 'antd';

import { i18n } from 'language/i18n';
import en from 'antd/lib/locale/en_US';
import tr from 'antd/lib/locale/tr_TR';
import FormItem from './FormItem';

const CustomDatePicker = ({
  name = '', placeholder = '', value = '', onChange = () => {}, ...props
}) => {
  const onChangeText = (date, dateString) => {
    onChange({
      name,
      value: dateString,
    });
  };
  return (
    <FormItem
      label={placeholder}
      name={name}
    >
      <ConfigProvider locale={i18n.language === 'en' ? en : tr}>
        <DatePicker
          onChange={onChangeText}
          value={value}
          format="MM/DD/YYYY"
          style={{ width: '100%' }}
          {...props}
        />
      </ConfigProvider>
    </FormItem>

  );
};

CustomDatePicker.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export default CustomDatePicker;
