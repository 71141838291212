import React from 'react';
import PropTypes from 'prop-types';

const CallTo = ({ tel, className, style }) => {
  return (
    <a
      className={className}
      style={style}
      href={`tel:${tel}`}
    >
      {tel}
    </a>
  );
};

CallTo.propTypes = {
  tel: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default CallTo;
