import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import Image from 'next/image';
import _ from 'lodash';

import { withTranslation } from 'language/i18n';
import machineBrands from 'constants/machineBrands.json';
import machineCategories from 'constants/machineCategories.json';
import FooterForm from './FooterForm';

const FooterTall = ({ t }) => {
  const [aboutUsVisible, setAboutUsVisible] = useState(true);
  const [supportVisible, setSupportVisible] = useState(true);
  const [popularBrandsVisible, setPopularBrandsVisible] = useState(true);
  const [popularCategoriesVisible, setPopularCategoriesVisible] = useState(true);
  const [moreTextVisible, setMoreTextVisible] = useState(false);

  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-15 col-md-8 col-lg-10 order-2 order-md-1">
            <div className="row mb-md-4 mb-lg-0">
              <div className="col-15 col-sm-7 col-lg mb-sm-3 mb-lg-0">
                <div className={`im-accordion im-accordion--rotate-icon ${aboutUsVisible ? 'im-accordion--active' : ''}`}>
                  <div className="im-accordion__head" onClick={() => setAboutUsVisible((val) => !val)}>
                    <div className="im-accordion__head__title">{t('aboutUs')}</div>
                    <div className="im-accordion__head__toggle-icon">
                      <i className="far fa-angle-down" />
                    </div>
                  </div>
                  <div className="im-accordion__body pb-3 pb-lg-0">
                    <nav>
                      <Link href="/about-us">
                        <a>{t('whoAreWe')}</a>
                      </Link>
                      <Link href="/contact-us">
                        <a>{`${t('contactUs')}`}</a>
                      </Link>
                      <Link href="/content/buying/process-of-buying">
                        <a>{t('howDoIBuy')}</a>
                      </Link>
                      <Link href="/content/selling/simple-sales-process">
                        <a>{t('howDoISell')}</a>
                      </Link>
                      <Link href="https://kariyer.iyimakina.com/">
                        <a>{t('career')}</a>
                      </Link>
                      <Link href="/">
                        <a>{t('distanceSellingAgreement')}</a>
                      </Link>
                      <Link href="/">
                        <a>{t('userAndPrivacyPolicy')}</a>
                      </Link>
                      <Link href="/agreements/terms-of-use">
                        <a>{t('termsOfUse')}</a>
                      </Link>
                      <Link href="/agreements/kvkk-policy">
                        <a>{t('kvkkPolicy')}</a>
                      </Link>
                      <Link href="/agreements/kvkk-clarification-text">
                        <a>{t('kvkkClarificationText')}</a>
                      </Link>
                      <Link href="/agreements/cookie">
                        <a>{t('cookiePolicy')}</a>
                      </Link>
                      <Link href="/">
                        <a>{t('collaborations')}</a>
                      </Link>
                      <Link href="/blog">
                        <a>{t('blog')}</a>
                      </Link>
                    </nav>
                  </div>
                </div>
              </div>
              <div className="col-15 col-sm-7 col-lg mb-sm-3 mb-lg-0">
                <div className={`im-accordion im-accordion--rotate-icon ${supportVisible ? 'im-accordion--active' : ''}`}>
                  <div className="im-accordion__head" onClick={() => setSupportVisible((val) => !val)}>
                    <div className="im-accordion__head__title">{t('support')}</div>
                    <div className="im-accordion__head__toggle-icon">
                      <i className="far fa-angle-down" />
                    </div>
                  </div>
                  <div className="im-accordion__body pb-3 pb-lg-0">
                    <nav>
                      <Link href="/content/buying/before-bidding">
                        <a>{t('buying')}</a>
                      </Link>
                      <Link href="/content/selling/auction-process">
                        <a>{t('selling')}</a>
                      </Link>
                      <Link href="/faq/buyers">
                        <a>{t('faq')}</a>
                      </Link>
                      <Link href="/content/refund-conditions">
                        <a>{t('refundConditions')}</a>
                      </Link>
                      <Link href="/content/expertise-and-valuation">
                        <a>{t('expertiseAndValuation')}</a>
                      </Link>
                      <Link href="/content/shipping-and-delivery">
                        <a>{t('shippingAndDelivery')}</a>
                      </Link>
                      <Link href="/content/amount-of-deposit-and-bidding-limit">
                        <a>{t('amountOfDepositAndBiddingLimit')}</a>
                      </Link>
                    </nav>
                  </div>
                </div>
              </div>
              <div className="col-15 col-sm-7 col-lg mb-sm-3 mb-lg-0">
                <div className={`im-accordion im-accordion--rotate-icon ${popularBrandsVisible ? 'im-accordion--active' : ''}`}>
                  <div className="im-accordion__head" onClick={() => setPopularBrandsVisible((val) => !val)}>
                    <div className="im-accordion__head__title">Popüler Markalar</div>
                    <div className="im-accordion__head__toggle-icon">
                      <i className="far fa-angle-down" />
                    </div>
                  </div>
                  <div className="im-accordion__body pb-3 pb-lg-0">
                    <nav>
                      {
                        _.map(machineBrands, (brand) => (
                          <Link key={brand.id} href={`/brands/detail/${brand.id}`} as={`/brands/detail/${brand.id}`}>
                            <a>{brand.title}</a>
                          </Link>
                        ))
                      }
                      <Link href="/brands">
                        <a>Tüm İş Makinası Markaları</a>
                      </Link>
                    </nav>
                  </div>
                </div>
              </div>
              <div className="col-15 col-sm-7 col-lg mb-sm-3 mb-lg-0">
                <div className={`im-accordion im-accordion--rotate-icon ${popularCategoriesVisible ? 'im-accordion--active' : ''}`}>
                  <div className="im-accordion__head" onClick={() => setPopularCategoriesVisible((val) => !val)}>
                    <div className="im-accordion__head__title">Popüler Kategoriler</div>
                    <div className="im-accordion__head__toggle-icon">
                      <i className="far fa-angle-down" />
                    </div>
                  </div>
                  <div className="im-accordion__body pb-3 pb-lg-0">
                    <nav>
                      {
                        _.map(machineCategories, (category) => (
                          <Link key={category.id} href={`/brands/categories/detail/${category.id}`} as={`/brands/categories/detail/${category.id}`}>
                            <a>{category.title}</a>
                          </Link>
                        ))
                      }
                      <Link href="/brands/categories">
                        <a>Tüm İş Makinası Kategorileri</a>
                      </Link>
                    </nav>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-15 col-sm-15 col-lg-11 order-2 order-sm-1 mb-4">
                <span className="fs-12 fw-700 text-gray2">
                  Destekleyen Kuruluşlar
                </span>
                <div className="row ml-1 mt-3 align-items-center">
                  <div className="col-2">
                    <Image
                      src="/image/tubitak-logo.png"
                      width="45"
                      height="59"
                      layout="responsive"
                    />
                  </div>
                  <div className="footer-supports-line ml-4 mr-4" />
                  <div className="col-4">
                    <Image
                      src="/image/ituari-teknokent-logo.png"
                      width="166"
                      height="47"
                    />
                  </div>
                  <div className="footer-supports-line ml-4 mr-4" />
                  <div className="col-4">
                    <Image
                      src="/image/ostim-logo.png"
                      width="297"
                      height="93"
                    />
                  </div>
                </div>
              </div>
              <div className="col-15 col-sm-15 col-lg-4 order-1 order-sm-2 mb-4">
                <h4 className="fs-12 mb-3 text-gray2">Sosyal Medya Kanalları</h4>
                <nav className="social">
                  <a href="https://www.facebook.com/iyimakina" rel="noopener noreferrer" target="_blank">
                    <i className="fab fa-facebook-f" />
                  </a>
                  <a href="https://www.instagram.com/iyimakina" rel="noopener noreferrer" target="_blank">
                    <i className="fab fa-instagram" />
                  </a>
                  <a href="https://www.youtube.com/iyimakina" rel="noopener noreferrer" target="_blank">
                    <i className="fab fa-youtube" />
                  </a>
                  <a href="https://www.linkedin.com/company/iyimakina" rel="noopener noreferrer" target="_blank">
                    <i className="fab fa-linkedin-in" />
                  </a>
                </nav>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-15 order-2 order-sm-1">
                <span className="text-gray2 fs-12">
                  <span className="fw-700 mr-2">
                    {`iyiMakina.com © ${new Date().getFullYear()}`}
                  </span>
                  Tüm Hakları Saklıdır.
                </span>
                <p className="mt-3 fs-11">
                  iyiMakina.com sitesinde yayınlanan her türlü bilgi, içerik ve görselin gerçekliği, orijinalliği, güvenliği ve
                  doğruluğuna ilişkin sorumluluk bu içerikleri giren kullanıcıya ait olup,
                  {' '}
                  <span className="fw-700">iyiMakina.com'un bu hususlarla ilgili herhangi bir sorumluluğu bulunmamaktadır.</span>
                </p>
                <nav className="nav--horizontal">
                  <Link href="/agreements/kvkk-policy">
                    <a>Kişisel Verilerin Korunması</a>
                  </Link>
                  <Link href="/agreements/terms-of-use">
                    <a>Kullanım Koşulları</a>
                  </Link>
                  <Link href="/agreements/kvkk-clarification-text">
                    <a>Aydınlatma Metni</a>
                  </Link>
                  <Link href="/agreements/cookie">
                    <a>Çerez Politikası</a>
                  </Link>
                </nav>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col">
                <p>
                  Türkiye’nin ilk ve tek ikinci el iş makinası online açık artırma sitesi iyiMakina.com, 44 yıllık sektör tecrübesiyle hizmet vermektedir. İş makinası almak ve satmak isteyen kullanıcıları güvenilir ve sorunsuz bir platform altında toplayan iyiMakina, ikinci el iş makinası satın alma deneyimini kolay, adil, hızlı ve  güvenilir hale getirmektedir.
                </p>
                <p>
                  Online teklif kolaylığı, bağımsız ekspertiz raporu, 30 gün içinde satış, güvenilir ve şeffaf teklif imkanı ile 2. El iş makina alıp satmanın en iyi yolu mottosuyla, Türkiye’de iş makinası sektörünün standartlarını her geçen gün yükseltmeye devam ediyor.
                </p>
                {!moreTextVisible && <a onClick={() => setMoreTextVisible(true)} className="text-gray14 link-hover-white js-read-more">Tümünü Oku</a>}
                {
                  moreTextVisible
                  && (
                    <div className="footer-more-text-container">
                      <h4 className="fs-14 mb-3 mt-3">Aradığınız İş Makinası iyiMakina’da</h4>
                      <p>
                        iyiMakina.com’un avantajlarla satışa sunduğu birçok kategorideki iş makinaları, müteahhit firmalar, maden firmaları, tarım firmaları başta olmak üzere yüzlerce firmanın aradığı makinalar iyiMakina.com’da sizi bekliyor. Ekskavatör(Kepçe), Beko Loder(Kazıcı-Yükleyici), Loder(Yükleyici), Asfalt Makinaları, Beton Pompaları, Dozer, Finişer, Fore Kazık Makinası, Greyder, Kar Küreme Aracı, Kaya Delici(Ankraj) Kırma Eleme Tesisi, Kule Vinç, Mobil Asansör, Mobil Konkasör, Mobil Vinç, Silindir, Sondaj Makinası, Telehandler (Teleskopik Yükleyici), Transmikser ve onlarca makina çeşidi arasından istediğiniz makinaya, istediğiniz teklifi yapın, geri kalan her şeyi biz halledelim.
                      </p>
                      <h4 className="fs-14 mb-3">Dünyaca Ünlü Ekskavatör(Kepçe) Markaları Burada</h4>
                      <p>
                        İnşaat, hafriyat ve maden sektörü dahil birçok sektörün ihtiyacı olan Satılık Ekskavatör markaları iyiMakina.com güvencesiyle satışa hazır. Caterpillar(Cat), Hitachi, Hyundai, Hidromek, Komatsu, Sumitomo, Volvo, Doosan markalı ekskavatör makinalar, satışa hazır şekilde sizi bekliyor.
                      </p>
                      <h4 className="fs-14 mb-3">Sektörün Yükünü Kaldıran Loder(Yükleyiciler) iyiMakina’da</h4>
                      <p>
                        Birçok sektörün aradığı iş makinası olan Sahibinden Satılık Loder tipi iş makinaları tek çatı altında toplandı. Cat, Hitachi, Hyundai, JCB, Kawasaki Komatsu, New Holland marka loderler yeni sahiplerini bekliyor. Üstelik bağımsız ekspertiz raporu ve online teklif kolaylığıyla.
                      </p>
                      <h4 className="fs-14 mb-3">Tonlarca Yük Kaldıran Kaya Kamyonları Bir Arada</h4>
                      <p>
                        Maden sektörünün vazgeçilmezi kaya kamyonları, online açık artırma rahatlığıyla satışa sunuluyor. Tek yapmanız gereken; iyiMakina.com üzerinden tek tık ile teklif vermek. Mercedes, BMC, Caterpillar, Komatsu, Volvo başta olmak üzere Satılık Kaya Kamyonlarını hemen inceleyin, aradığınız makinayı bulun.
                      </p>
                      <h4 className="fs-14 mb-3">Her Türlü Engeli Aşan Dozerler</h4>
                      <p>
                        Alt yapı ve üst yapı işlerinde her türlü engeli aşan Satılık Dozerler birkaç tık uzağınızda. Boyasından motoruna, paletinden kornasına kadar her ayrıntıyı sizlere sunuyoruz, Aradığınız makinayı en doğru ve hızlı kanaldan bulmanızı sağlıyoruz. Paleti ve tekerlekli dozerler için Caterpillar, Fiat, Hidromek, JCB, Komatsu, Shantui, Massey Ferguson markalarını inceleyebilir, online açık artırmalara katılabilir veya izleyebilirsiniz.
                      </p>
                      <h4 className="fs-14 mb-3">Her Türlü İşin Vazgeçilmezi Beko Loder’ler</h4>
                      <p>
                        Sahibinden Satılık Beko Loder arayanların işini kolaylaştırıyoruz. Alıcı ve satıcı arasındaki tüm sorunları hallediyor, en hızlı şekilde makinanızı almanızı sağlıyoruz. Çukurova Makina, Case, Hidromek, JCB, Kawasaki, Mastaş, MST, New Holland, Volvo marka olmak üzere yerli ve yabancı satılmaya hazır makinalar.
                      </p>
                      <h4 className="fs-14 mb-3">Yolların Vazgeçilmezi Finişer ve Asfalt Makinaları</h4>
                      <p>
                        Yol yapım işlerinde en çok kullanılan iş makinaları avantajlarla iyiMakina.com’da. Satılık Finişer ve Satılık Asfalt Makinalarını internet sitemize girerek, detaylı bilgilerine ulaşabilir, sormak istediğiniz soruları çağrı merkezimize bağlanarak sorabilirsiniz. Wirtgen, Vögele, Bobcat, Veermer markalı asfalt makinaları sitemizde alıcısını bekliyor.
                      </p>
                      <h4 className="fs-14 mb-3">Çalışır Vaziyette, Temiz Kullanılmış Beton Pompası ve Mikserler Burada</h4>
                      <p>
                        Düşük kilometreli, az kullanılmış, Sahibinden Satılık Beton Pompası ve Satılık Mikser Makinaları iyiMakina Güvencesiyle satışa sunuluyor. 30 gün süren online açık artırmarlarımıza katılabilir, Betonmak, Betonstar, Betonser, Putzmeister, Schwing, Waitzinger, Sermac, Mercedes-Benz, Brinkmann marka araçları avantajlı fiyatlarla satın alabilirsiniz.
                      </p>
                      <h4 className="fs-14 mb-3">Sahibinden Satılık Greyderler</h4>
                      <p>
                        Detaylı fotoğraflanmış ve ayrıntılı incelenmiş raporları ücretsiz olarak inceleyebilirsiniz. Uzman ekspertiz ekibimiz iyiMakina’daki tüm makinaları yerinde, detaylı olarak incelemektedir. Dünyaca ünlü Greyder markaları Komatsu, Caterpillar, Champion, XCMG, Mitsubishi tek tık uzağınızda.
                      </p>
                      <h4 className="fs-14 mb-3">Alt Yapı İşlerinin Olmazsa Olmazı Fore Kazık Makinaları Burada</h4>
                      <p>
                        İkinci el Mini ve Fore Kazık Makinaları Türkiye’nin en büyük müteahhit firmalarının envanterinde satışı bekliyor. iyiMakina.com Alıcılar ile Satıcıları en kolay yoldan buluşturuyor. Dünyaca ünlü Bauer, Casagrande, Liebherr, Mait, Soilmec marka kazık makinaları sahibinden, aracısız olarak satışta.

                      </p>
                      <h4 className="fs-14 mb-3">Asfalt İşlerinin Kahramanı Silindirler Tek Adreste</h4>
                      <p>
                        İhtiyacınız olan, satılık ikinci el, çalışır durumda, uygun fiyatlı Silindir’ler web sitemizde. Türkiye geneli nakliye kolaylığı ile satın alabileceğiniz silindirlere istediğiniz yerden, istediğiniz fiyatı teklif edebilir, uygun fiyata satın alabilirsiniz. Boomag, Caterpillar, Dynapac, Hamm, JCB, Hyundai marka silindirler tek adreste, iyiMakina’da.
                      </p>
                      <p>
                        2. El iş makinası alıp satmanın en kolay yolu olan iyiMakina.com, ekspertiz raporlarına ücretsiz bakmanızı, teminat bedeli ödeyerek online açık artırmalara katılmanızı ve aradığınız makinayı hızlı ve güvenilir şekilde satın almanızı sağlıyor. Alanında uzman ekspertiz ekibimiz, saha ekibimiz, yazılım ekibimiz, yönetim ekibimiz, 7 gün 24 saat soru, sorun ve önerilerinizi iletebileceğiniz çağrı merkezimiz ile Türkiye’nin ilk ve tek ikinci el iş makinası açık artırma sitesi iyiMakina hizmetinizde.
                      </p>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
          <div className="col-15 col-md-7 col-lg-5 order-1 order-md-2 mb-5 mb-md-0">
            <div className="im-box im-box--shadow-black im-box--gray">
              <FooterForm />
            </div>
          </div>
        </div>
      </div>
      <div className="im-button im-button--transparent go-top-button">
        <i className="fas fa-chevron-up" />
        <div>YUKARI ÇIK</div>
      </div>
      <div className="col mt-5" style={{ backgroundColor: '#4d4e4e' }}>
        <div className="container">
          <div className="row justify-content-between align-items-center" style={{ minHeight: 60 }}>
            <div className="col-15 col-lg-auto mt-lg-0 mt-2 d-flex justify-content-center">
              <span className="text-gray2 fw-700 fs-13 text-center">
                {`© 2019-${new Date().getFullYear()}   |   iyiMakina Bilgi Teknolojileri ve Ticaret A.Ş.`}
              </span>
            </div>
            <div className="d-none d-lg-block text-center">
              <div className="footer-supports-line ml-4 mr-4" />
            </div>
            <div className="col-15 col-lg-auto mt-lg-0 mt-2 d-flex justify-content-center">
              <span className="text-gray2 fw-700 fs-13 text-center">Resitpasa Mh. Katar Cd. ITU Teknokent ARI 4 No: 2/50-6, Sariyer/Istanbul - Turkiye</span>
            </div>
            <div className="d-none d-lg-block text-center">
              <div className="footer-supports-line ml-4 mr-4" />
            </div>
            <div className="col-15 col-lg-auto mt-lg-0 my-2 d-flex justify-content-center">
              <img src="/image/domestic_production.png" width="71" height="28" alt="yerli üretim" />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

FooterTall.propTypes = {
  t: PropTypes.func,
};

export default withTranslation('common')(FooterTall);
