/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Request from './Request';
import Success from './Success';

const LimitIncreaseModal = ({ visible, setVisible }) => {
  const [successSectionVisible, setSuccessSectionVisible] = useState(false);

  useEffect(() => {
    const body = document.getElementsByClassName('body');
    if (visible) {
      body[0]?.classList?.add('stopScrolling');
      document.addEventListener('keydown', handleEsc);
    } else {
      body[0]?.classList?.remove('stopScrolling');
      document.removeEventListener('keydown', handleEsc);
    }
  }, [visible]);

  const handleEsc = (event) => {
    if (event.keyCode === 27) {
      setVisible(false);
    }
  };

  return (
    <div
      className={`modal fade bd-example-modal-lg im-modal ${visible ? 'show' : ''}`}
      style={{ display: visible ? 'block' : 'none' }}
    >
      <div className="modal-dialog modal-lg modal-dialog-centered animate__animated animate__fadeInDown">
        <div className="modal-content modal-transparent">
          <div className="modal-body p-0">
            {
              successSectionVisible
                ? (
                  <Success
                    setVisible={setVisible}
                  />
                )
                : (
                  <Request
                    setSuccessSectionVisible={setSuccessSectionVisible}
                    setVisible={setVisible}
                  />
                )
            }
          </div>
        </div>
      </div>
      <div className="modal-close-button" onClick={() => setVisible(false)}>
        <i className="fas fa-times" />
        <div>ESC</div>
      </div>
    </div>
  );
};

LimitIncreaseModal.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
};

export default LimitIncreaseModal;
