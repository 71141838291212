import React from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'antd';
import _ from 'lodash';
import { i18n } from 'language/i18n';

import FormItem from './FormItem';

const TextInput = ({
  name = '', options = [], onChange = () => {}, value = '', children, groupStyle, required = false, withoutFormItem, ...props
}) => {
  const onChangeRadio = (event) => {
    const { target } = event;
    const inputValue = target.type === 'checkbox' ? target.checked : target.value;

    onChange({
      name,
      value: inputValue,
    });
  };
  if (withoutFormItem) {
    return (
      <Radio.Group
        onChange={onChangeRadio}
        options={options}
        style={groupStyle}
        value={value}
        {...props}
      >
        {
          children || _.map(options, (option, index) => (
            <Radio
              key={index}
              value={option.value}
              checked={option.default}
            >
              {option.label}
            </Radio>
          ))
        }
      </Radio.Group>
    );
  }
  return (
    <FormItem
      name={name}
      rules={[{ required, message: i18n.t('cantBeEmpty') }]}
    >
      <Radio.Group
        onChange={onChangeRadio}
        options={options}
        style={groupStyle}
        value={value}
        {...props}
      >
        {
          children || _.map(options, (option, index) => (
            <Radio
              key={index}
              value={option.value}
              checked={option.default}
            >
              {option.label}
            </Radio>
          ))
        }
      </Radio.Group>
    </FormItem>
  );
};

TextInput.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  containerClassName: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  groupStyle: PropTypes.object,
  validator: PropTypes.func,
  required: PropTypes.bool,
  withoutFormItem: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number]),
};

export default TextInput;
