import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import _ from 'lodash';
import isEmpty from 'lodash/isEmpty';

import { i18n } from 'language/i18n';
import FormItem from './FormItem';

const TextInput = ({
  name = '', label = '', placeholder = '', value = '', type = 'text', onChange = () => {}, inputRef, validator, required = false, hideLabel = false, formItemStyle = {}, ...props
}) => {
  const onChangeText = (event) => {
    const { target } = event;
    const inputValue = target.type === 'checkbox' ? target.checked : target.value;

    onChange({
      name,
      value: inputValue,
    });
  };

  return (
    <FormItem
      label={!hideLabel && (!_.isEmpty(label) ? label : placeholder)}
      name={name}
      rules={[{ validator }, { required, message: i18n.t('cantBeEmpty') }]}
      {...(!isEmpty(formItemStyle) ? { style: formItemStyle } : {})}
    >
      <Input
        placeholder={placeholder}
        autoComplete="off"
        onChange={onChangeText}
        value={value}
        type={type}
        ref={(input) => {
          if (inputRef) {
            inputRef(input);
          }
        }}
        {...props}
      />
    </FormItem>
  );
};

TextInput.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  inputRef: PropTypes.func,
  validator: PropTypes.func,
  required: PropTypes.bool,
  hideLabel: PropTypes.bool,
  formItemStyle: PropTypes.object,
};

export default TextInput;
