// CMS Constants

export const MACHINE_STATE = {
  UNSET: 'UNSET',
  INSPECTED: 'INSPECTED',
  READY_FOR_EVENT: 'READY_FOR_EVENT',
  WAITING_EVENT_DATE: 'WAITING_EVENT_DATE',
  IN_EVENT: 'IN_EVENT',
  SUSPENDED: 'SUSPENDED',
  SOLD: 'SOLD',
  UNSOLD: 'UNSOLD',
};

export const MACHINE_CONDITION = {
  POOR: 'Zayıf',
  AVERAGE: 'Orta',
  GOOD: 'İyi',
  EXCELLENT: 'Mükemmel',
};

export const TEMPLATE_TYPE = {
  GENERAL: 'GENERAL',
  DETAIL: 'DETAIL',
};

export const USER_MEMBERSHIP_TYPE = {
  INDIVIDUAL: 'Bireysel Üyelik',
  CORPORATE: 'Kurumsal Üyelik',
};

export const USER_TYPE = {
  BUYER: 'BUYER',
  SELLER: 'SELLER',
};

export const ORDER_STATE = {
  PENDING: 'PENDING',
  STARTED: 'STARTED',
  READY_FOR_SHIPPING: 'READY_FOR_SHIPPING',
  WAITING_FOR_SHIPPING: 'WAITING_FOR_SHIPPING',
  IN_SHIPPING: 'IN_SHIPPING',
  SHIPPED: 'SHIPPED',
  COMPLETED: 'COMPLETED',
  SELLER_DECLINED: 'SELLER_DECLINED',
  BUYER_DECLINED: 'BUYER_DECLINED',
};

export const BANK_ACCOUNT_TYPE = {
  DEPOSIT: 'DEPOSIT',
  TRANSACTION: 'TRANSACTION',
};

export const BUYING_FORMAT = {
  BID: 'BID',
  OFFER: 'OFFER',
  BUY_NOW: 'BUY_NOW',
};

export const SLIDER_TYPE = {
  BUYER: 'BUYER',
  SELLER: 'SELLER',
};

export const GENDER = {
  MALE: 'MALE',
  FEMALE: 'FEMALE',
};

// Marketplace Constants

export const SOCKET_EMIT = {
  JOIN_ROOM: 'joinRoom',
  LEAVE_ROOM: 'leaveRoom',
};

export const SOCKET_LISTEN = {
  OFFER_PLACED: 'OFFER_PLACED',
  OFFER_PLACED_RESPONSE: 'OFFER_PLACED_RESPONSE',
  BUYING_LIMIT: 'BUYING_LIMIT',
  COUNTER_OFFER_PLACED_RESPONSE: 'COUNTER_OFFER_PLACED_RESPONSE',
  OFFER_COUNTERED: 'OFFER_COUNTERED',
};

export const COUNTER_OFFER_STATUS = {
  PENDING: 'PENDING',
  ACCEPTED: 'ACCEPTED',
  COUNTERED: 'COUNTERED',
  REJECTED: 'REJECTED',
};

export const OFFER_STATE = {
  HIGHEST_OFFER: 'HIGHEST_OFFER',
  OUTBID_OFFER: 'OUTBID_OFFER',
};
