import React, { useEffect } from 'react';

/* import { i18n } from 'language/i18n'; */

const FooterShort = () => {
/*   const [currentLanguage, setCurrentLanguage] = useState('tr'); */

  useEffect(() => {
    // setCurrentLanguage(i18n.language);
  }, []);

  return (
    <footer className="footer-2">
      <div className="container">
        <div className="row">
          {/*  <div className="col">
            <select
              className="im-select-blank js-language-select"
              name="Language"
              onChange={(event) => {
                i18n.changeLanguage(event.target.value);
              }}
            >
              <option value="">Seçiniz</option>
              <option selected={currentLanguage === 'tr'} value="tr">Türkçe</option>
              <option selected={currentLanguage === 'en'} value="en">İngilizce</option>
            </select>
          </div> */}
          <div className="col text-right">
            <nav className="horizontal-links">
              <a href="/faq/sellers" target="_blank">Yardım</a>
              <a>Gizlilik</a>
              <a>Şartlar</a>
            </nav>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterShort;
