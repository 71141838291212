import React from 'react';
import PropTypes from 'prop-types';

const MailTo = ({ mail, className, style }) => {
  return (
    <a
      className={className}
      style={style}
      href={`mailto:${mail}`}
    >
      {mail}
    </a>
  );
};

MailTo.propTypes = {
  mail: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default MailTo;
