/* eslint-disable consistent-return */
import request from 'utils/request';

export const getMachinery = async (filter) => {
  const data = await request(`api/v1/machines${filter ? `?${filter}` : ''}`, 'get');
  return data;
};

export const getSampleMachinery = async (count) => {
  const data = await request(`api/v1/machines/sample?sample=${count}`, 'get');
  return data;
};

export const getMachine = async (id) => {
  const data = await request(`api/v1/machines/${id}`, 'get');
  return data;
};

export const getMachineOrder = async (machineId) => {
  const data = await request(`api/v1/orders/${machineId}`, 'get');
  return data;
};

export const saleRequest = async ({
  country,
  region,
  district,
  postalCode,
  brand,
  machineType,
  model,
  serialNumber,
  rate,
  workingHours,
  machineYear,
  customerName,
  customerSurname,
  customerMail,
  customerPhone,
  customerCountry,
  customerIdentificationNumber,
  customerTitle,
  customerCompanyName,
  customerTaxOffice,
  customerTaxNumber,
  customerLearnedFrom,
  customerMachineParkSize,
}) => {
  const { saleRequest: data } = await request('api/v1/form/saleRequest', 'post', {
    body: JSON.stringify({
      country,
      region,
      district,
      postalCode,
      brand,
      machineType,
      model,
      serialNumber,
      rate,
      workingHours,
      machineYear,
      customerName,
      customerSurname,
      customerMail,
      customerPhone,
      customerCountry,
      customerIdentificationNumber,
      customerTitle,
      customerCompanyName,
      customerTaxOffice,
      customerTaxNumber,
      customerLearnedFrom,
      customerMachineParkSize,
    }),
  });
  return data;
};

export const getResendVerficationCode = async (requestId) => {
  const data = await request(`api/v1/form/saleRequest/resendVerificationCode/${requestId}`, 'get');
  return data;
};

export const saleOtpRequest = async (requestId, otpCode) => {
  const data = await request(`api/v1/form/saleRequest/${requestId}`, 'post', {
    body: JSON.stringify({
      otpCode,
    }),
  });
  return data;
};
