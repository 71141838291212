const maskAmount = (value, currency = 'EUR', hideCurrenyCode = false) => {
  return Intl && Intl.NumberFormat('tr-TR', {
    style: hideCurrenyCode ? 'decimal' : 'currency',
    currency,
    currencyDisplay: 'symbol',
    minimumFractionDigits: 0,
  }).format(value);
};

export default maskAmount;
