import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';

const CustomForm = ({ children, formRef, ...props }) => {
  return (
    <Form
      {...props}
      ref={(input) => {
        if (formRef) {
          formRef(input);
        }
      }}
    >
      {children}
    </Form>
  );
};

CustomForm.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  formRef: PropTypes.func,
};

export default CustomForm;
