import React, { useState } from 'react';

import PropTypes from 'prop-types';

const AccordionMenuElement = ({ title, children }) => {
  const [visible, setVisible] = useState(false);

  return (
    <div className={`im-accordion im-accordion--no-shadow ${visible ? 'im-accordion--active' : ''}`}>
      <div className="im-accordion__head" onClick={() => setVisible(!visible)}>
        <div className="im-accordion__head__title">{title}</div>
        <div className="im-accordion__head__toggle-icon">
          <i className="far fa-plus" />
          <i className="far fa-minus" />
        </div>
      </div>
      <div className="im-accordion__body">
        {children}
      </div>
    </div>
  );
};

AccordionMenuElement.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  title: PropTypes.string,
};

export default AccordionMenuElement;
