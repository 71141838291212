/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import FormItem from './FormItem';

const antIcon = <LoadingOutlined style={{ fontSize: 24, color: 'black' }} spin />;

const CustomButton = ({
  label, loading = false, children, withoutFormItem, ...props
}) => {
  if (withoutFormItem) {
    return (
      <Button type="primary" disabled={loading} {...props}>
        {
          loading
            ? <Spin indicator={antIcon} />
            : (
              label || children
            )
        }
      </Button>
    );
  }
  return (
    <FormItem>
      <Button type="primary" disabled={loading} {...props}>
        {
          loading
            ? <Spin indicator={antIcon} />
            : (
              label || children
            )
        }
      </Button>
    </FormItem>
  );
};

CustomButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  label: PropTypes.string,
  loading: PropTypes.bool,
  withoutFormItem: PropTypes.bool,
};

export default CustomButton;
